import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  DropDownListButton,
  loginStyle,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ForgetPasswordPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      tenant: Object.values(this.props.tenantList.tenant_map)[0],
    };
  }

  render() {
    return (
      <div style={loginStyle.RiskCreationFormWrapStyle}>
        <div style={loginStyle.RiskCreationFormStyle}>
          <div style={loginStyle.popupFormBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Reset your password
            </div>
            <div style={{ height: "3.125rem" }} />
            <div style={mapping["forms/label/1-default"]}>
              Please fill in following information and click "Send reset
              request", an email contains the verification token will be sent to
              your email, and use that token to reset your password.
            </div>
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Username"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ username: event.value });
              }}
            />
            <div
              style={{
                ...mapping["inlinetext/Italicized/lightleft"],
                fontSize: "14px",
                color: mapping["Color_Basic_Danger"],
              }}
            >
              {this.props.usernameError ? "please enter a valid username" : ""}
            </div>
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Email"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ email: event.value });
              }}
            />
            <div
              style={{
                ...mapping["inlinetext/Italicized/lightleft"],
                fontSize: "14px",
                color: mapping["Color_Basic_Danger"],
              }}
            >
              {this.props.emailError ? "please enter a valid email" : ""}
            </div>
            <div style={loginStyle.fieldPadding} />
            <DropDownListButton
              title={"Tenant"}
              DropDownButtonStyle={loginStyle.popupDropDownStyle}
              value={Object.values(this.props.tenantList.tenant_map)[0]}
              data={Object.values(this.props.tenantList.tenant_map)}
              CustomOnChange={(event) => {
                //console.log(event.value);
                this.setState({ tenant: event.value });
              }}
            />
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={loginStyle.popupCancelButtonWidth}
                height={loginStyle.popupButtonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Send reset request"}
                color={"Primary"}
                width={loginStyle.popupResetButtonWidth}
                height={loginStyle.popupButtonHeight}
                clickEvent={() => {
                  this.props.submitFunction(this.state);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { ForgetPasswordPopup };
