import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  loginStyle,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ResetPasswordPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.prefillValue.username,
      email: this.props.prefillValue.email,
      tenant: this.props.prefillValue.tenant,
      ou_name: "",
      new_password: "",
      repeat_password: "",
    };
  }

  render() {
    return (
      <div style={loginStyle.RiskCreationFormWrapStyle}>
        <div style={loginStyle.RiskCreationFormStyle}>
          <div style={loginStyle.popupFormBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Reset your password
            </div>
            <div style={{ height: "50px" }} />
            <div style={mapping["forms/label/1-default"]}>
              please fill in the token you received via email, and new password
            </div>

            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Username"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              value={this.props.prefillValue.username}
              disabled={true}
            />
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Email"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              value={this.props.prefillValue.email}
              disabled={true}
            />
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Tenant"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              value={this.props.prefillValue.tenant}
              disabled={true}
            />
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Token"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ token: event.value });
              }}
            />
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"New password"}
              type={"password"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ new_password: event.value });
              }}
            />
            <div style={{ height: "1rem" }}>
              <WarningMsg value={this.WarningMsgNew1()} />
            </div>
            <div style={loginStyle.fieldPadding} />
            <NonValidationInput
              title={"Repeat password"}
              type={"password"}
              fieldstyle={loginStyle.InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ repeat_password: event.value });
              }}
            />
            <div style={{ height: "1rem" }}>
              <WarningMsg value={this.WarningMsgNew()} />
            </div>
            <div style={{ height: "2.5rem" }} />

            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={loginStyle.popupCancelButtonWidth}
                height={loginStyle.popupButtonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Reset Password"}
                color={"Primary"}
                width={loginStyle.popupResetButtonWidth}
                height={loginStyle.popupButtonHeight}
                disabled={
                  this.WarningMsgNew() ||
                  this.WarningMsgNew1() ||
                  this.state.new_password === "" ||
                  this.state.repeat_password === ""
                }
                clickEvent={() => {
                  this.props.submitFunction(this.state);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  WarningMsgNew = () => {
    if (
      this.state.new_password !== "" &&
      this.state.repeat_password !== "" &&
      this.state.new_password !== this.state.repeat_password
    ) {
      return "Passwords do not match, please check again.";
    }
  };
  WarningMsgNew1 = () => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    if (
      passwordValidator(this.state.new_password) !== "valid" &&
      this.state.new_password !== ""
    ) {
      return "Please enter a valid new password.";
    }
  };
}

const WarningMsg = (props) => {
  return (
    <div
      style={{
        ...mapping["inlinetext/Italicized/lightleft"],
        fontSize: "14px",
        color: mapping["Color_Basic_Danger"],
      }}
    >
      {props.value}
    </div>
  );
};

export { ResetPasswordPopup };
