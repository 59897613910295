import React from "react";
import { Form, Field } from "@progress/kendo-react-form";

import {
  ValidationInput,
  loginStyle,
  mapping,
  ButtonSolid,
  DropDownListButton,
} from "@aim-mf/styleguide";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceAccount: false,
    };
  }
  render() {
    // console.log(this.props.tenantList);
    if (Object.keys(this.props.tenantList).length === 0) {
      return <div />;
    }
    const handleSubmit = (dataItem) => {
      // console.log();
      if (dataItem.email === "emailNotification") {
        this.setState({ serviceAccount: true });
        return;
      } else {
        this.setState({ serviceAccount: false });
      }
      this.props.login(
        dataItem.email,
        dataItem.password,
        Object.keys(this.props.tenantList.tenant_map).find(
          (key) => this.props.tenantList.tenant_map[key] === dataItem.tenant
        )
      );
    };

    return (
      <div style={loginStyle.formBgStyle}>
        <div style={loginStyle.formPadding}>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <form onSubmit={formRenderProps.onSubmit}>
                <fieldset>
                  <legend style={mapping["heading/h1/lightleft"]}>
                    Sign In
                  </legend>
                  <div style={loginStyle.tileFieldPadding}>
                    <Field
                      id={"tenant"}
                      title={"Instance"}
                      name={"tenant"}
                      type={"tenant"}
                      component={DropDownListButton}
                      DropDownButtonStyle={loginStyle.tenantDropDownStyle}
                      value={Object.values(this.props.tenantList.tenant_map)[0]}
                      data={Object.values(this.props.tenantList.tenant_map)}
                    />
                  </div>
                  <div style={{ height: "1.25rem" }} />
                  <div style={loginStyle.fieldsPadding}>
                    <Field
                      id={"email"}
                      title={"Username"}
                      name={"email"}
                      style={loginStyle.fieldStyle}
                      //type={"email"}
                      component={ValidationInput}
                      hint={"Email"}
                      //validator={emailValidator}
                    />
                  </div>
                  <div style={loginStyle.fieldsPadding}>
                    <Field
                      id={"password"}
                      title={"Password"}
                      name={"password"}
                      style={loginStyle.fieldStyle}
                      type={"password"}
                      component={ValidationInput}
                      hint={"Password"}
                    />
                  </div>
                </fieldset>
                {this.props.loginFail &&
                  !this.state.serviceAccount && (
                    <div style={{ color: "red", width: "17.5rem"  }}>
                      Incorrect tenant, username or password
                    </div>
                  )}
                {this.state.serviceAccount && (
                  <div style={{ color: "red", width: "17.5rem" }}>
                    the account is a service account and could not log in to the
                    system
                  </div>
                )}
                <div style={loginStyle.fieldButtonPadding}>
                  <ButtonSolid
                    name="Sign In"
                    height={loginStyle.loginButtonHeight}
                    width={loginStyle.loginButtonWidth}
                    color="Primary"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  />
                </div>
              </form>
            )}
          />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            style={loginStyle.forgetPasswordStyle}
            onClick={() => {
              this.props.handleForgetPassword();
            }}
            className={"div_hover"}
          >
            forget password ?
          </div>
          <div style={{ height: "2.5rem" }} />
        </div>
      </div>
    );
  }
}
export { Login };
