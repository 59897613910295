import React from "react";
import { loginStyle, ConfirmationPopup } from "@aim-mf/styleguide";
import { Login } from "./login";
import { Logo } from "./logo";
import { ForgetPasswordPopup } from "./forgetPasswordPopup";
import { ResetPasswordPopup } from "./resetPasswordPopup";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgetPassword: {
        display: false,
        usernameError: false,
        emailError: false,
      },
      resetPassword: {
        display: false,
        currentError: false,
        new1Error: false,
        new2Error: false,
      },
      resetRequest: {},
      forgetPasswordUserNotFound: this.props.forgetPasswordUserNotFound,
      tokenError: this.props.tokenError,
      passwordResetSuccess: this.props.passwordResetSuccess,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.forgetPasswordUserNotFound !==
      this.props.forgetPasswordUserNotFound
    ) {
      this.setState({
        forgetPasswordUserNotFound: nextProps.forgetPasswordUserNotFound,
      });
    }
    if (nextProps.tokenError !== this.props.tokenError) {
      this.setState({ tokenError: nextProps.tokenError });
    }
    if (nextProps.passwordResetSuccess !== this.props.passwordResetSuccess) {
      this.setState({ passwordResetSuccess: nextProps.passwordResetSuccess });
    }
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.forgetPassword.display ||
            this.state.resetPassword.display
              ? BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div
            style={Object.assign({}, loginStyle.backgroundImage, {
              backgroundImage: `url(${this.props.LoginBG})`,
            })}
          >
            <div style={{ display: "inline-flex" }}>
              <Logo />
              <div style={loginStyle.logoSeparatorLogin} />
              <Login
                loginFail={this.props.loginFail}
                tenantList={this.props.tenantList}
                login={this.props.login}
                handleForgetPassword={this.handleForgetPassword}
              />
            </div>
          </div>
        </div>
        {this.state.forgetPassword.display && (
          <ForgetPasswordPopup
            usernameError={this.state.forgetPassword.usernameError}
            emailError={this.state.forgetPassword.emailError}
            tenantList={this.props.tenantList}
            cancelFunction={this.handleForgetPasswordCancel}
            submitFunction={this.handleForgetPasswordSubmit}
          />
        )}
        {this.state.resetPassword.display && (
          <ResetPasswordPopup
            prefillValue={this.state.resetRequest}
            cancelFunction={this.handleResetPasswordCancel}
            submitFunction={this.handleResetPasswordSubmit}
          />
        )}
        {this.state.forgetPasswordUserNotFound && (
          <ConfirmationPopup
            title={"Request failed"}
            content={
              "you have input the invalid information, please confirm and enter again"
            }
            cancelFunction={() => {
              this.handleForgetPassword();
              this.handleResetPasswordCancel();
              this.setState({ forgetPasswordUserNotFound: false });
            }}
            confirmFunction={() => {
              this.handleForgetPassword();
              this.handleResetPasswordCancel();
              this.setState({ forgetPasswordUserNotFound: false });
            }}
          />
        )}
        {this.state.tokenError && (
          <ConfirmationPopup
            title={"Password reset failed"}
            content={
              "you have input the invalid information, please confirm and enter again"
            }
            cancelFunction={() => {
              this.handleForgetPasswordCancel();
              this.handleResetPassword();
              this.setState({ tokenError: false });
            }}
            confirmFunction={() => {
              this.handleForgetPasswordCancel();
              this.handleResetPassword();
              this.setState({ tokenError: false });
            }}
          />
        )}
        {this.state.passwordResetSuccess && (
          <ConfirmationPopup
            title={"Password reset success"}
            content={
              "you have reset your password, but login with the new password"
            }
            cancelFunction={() => {
              this.handleForgetPasswordCancel();
              this.handleResetPasswordCancel();
              this.setState({ passwordResetSuccess: false });
            }}
            confirmFunction={() => {
              this.handleForgetPasswordCancel();
              this.handleResetPasswordCancel();
              this.setState({ passwordResetSuccess: false });
            }}
          />
        )}
      </div>
    );
  }

  handleResetPasswordCancel = () => {
    this.setState({
      resetPassword: {
        display: false,
        currentError: false,
        new1Error: false,
        new2Error: false,
      },
      resetRequest: {},
    });
  };
  handleResetPassword = () => {
    this.setState({
      resetPassword: {
        display: true,
        currentError: false,
        new1Error: false,
        new2Error: false,
      },
    });
  };
  handleResetPasswordSubmit = (data) => {
    let payload = JSON.parse(JSON.stringify(data));
    payload.tenant = Object.keys(this.props.tenantList.tenant_map).find(
      (key) => {
        return this.props.tenantList.tenant_map[key] === data.tenant;
      }
    );
    this.props.resetPasswordAction(payload);
    this.handleForgetPasswordCancel();
  };
  handleForgetPassword = () => {
    this.setState({
      forgetPassword: {
        display: true,
        usernameError: false,
        emailError: false,
      },
    });
  };
  handleForgetPasswordCancel = () => {
    this.setState({
      forgetPassword: {
        display: false,
        usernameError: false,
        emailError: false,
      },
    });
  };
  handleForgetPasswordSubmit = (data) => {
    const usernameRegex = new RegExp(
      /^([A-Za-z0-9._-]+[\\])?[^/\\\[\]\:\;\|\=\,\+\*\?\<\>]{3,}$/
    );
    const usernameValidator = (value) =>
      !value
        ? "Field can not be empty"
        : usernameRegex.test(value)
        ? ""
        : "Please enter a valid username.";

    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const emailValidator = (value) =>
      emailRegex.test(value) ? "" : "Please enter a valid email.";

    if (
      usernameValidator(data.username) !== "" ||
      emailValidator(data.email) !== ""
    ) {
      let forgetPassword = this.state.forgetPassword;
      if (usernameValidator(data.username) !== "") {
        forgetPassword.usernameError = true;
      }
      if (emailValidator(data.email) !== "") {
        forgetPassword.emailError = true;
      }
      this.setState({ forgetPassword: forgetPassword });
    } else {
      this.setState({ resetRequest: data });
      let payload = JSON.parse(JSON.stringify(data));
      payload.tenant = Object.keys(this.props.tenantList.tenant_map).find(
        (key) => this.props.tenantList.tenant_map[key] === data.tenant
      );
      this.props.resetPasswordRequest(payload);
      this.handleForgetPasswordCancel();
      this.handleResetPassword();
    }
  };
}
const BlurEffectStyle = {
  filter: "blur(5px)",
};
export { LoginPage };
